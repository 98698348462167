import React from "react"
import "./AboveTheFold_form.scss"
import Img, { GatsbyImageProps } from "gatsby-image"
import ContactForm from "./ContactForm"

interface IAboveTheFoldForm {
  title: string
  text: string
  image: { childImageSharp: GatsbyImageProps }
  overlay?: boolean;
}

const AboveTheFoldForm: React.FunctionComponent<IAboveTheFoldForm> = props => {
  return (
    <section className="above-the-fold-form section-padding" id="contact-form">
      <div className="above-the-fold__background-image">
        <Img
          {...props.image.childImageSharp}
          style={{ height: "100%" }}
          loading="eager"
          fadeIn={false}
        />
      </div>
      {props.overlay && <div className="background-overlay"/>}
      <div className="above-the-fold__app1 container">
        <div className="above-the-fold__text">
          <div className="">
            <h1 className="heading heading--1">{props.title}</h1>
            <p className="paragraph paragraph--white paragraph--header" dangerouslySetInnerHTML={{ __html: props.text.replace(/\n\n/g, "</br>") }} />
          </div>
        </div>
        <div className="above-the-fold__form">
          <ContactForm location="pro" />
        </div>
      </div>
    </section>
  )
}

export default AboveTheFoldForm
