import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import AboveTheFoldForm from "../../AboveTheFold_form"
import "./ContactUs.scss"

const ContactUs: React.FunctionComponent<{}> = () => {
  const image = useStaticQuery(graphql`
    query {
      file(name: { eq: "contact-bg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2880
            quality: 100
            srcSetBreakpoints: [800, 1275, 1440, 1920, 2880]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <AboveTheFoldForm
      image={image.file}
      title="Contactez-nous !"
      text="Vous souhaitez proposer un nouveau service à vos clients, engager vos collaborateurs dans la réduction des déchets ou tout simplement poser vos questions… 
    Complétez ce formulaire et notre équipe reviendra très rapidement vers vous. 
    "
    />
  )
}

export default ContactUs
