import * as React from "react"

import "./ContactForm.scss"

type ContactType = typeof CONTACT_TYPE[number]["slug"]

const CONTACT_TYPE = [
  {
    slug: "restaurant",
    label: "Un restaurant indépendant ou chaîne de restaurants",
  },
  { slug: "cafeteria_entreprise", label: "Une cafétéria d'entreprise" },
  { slug: "distribution", label: "Une enseigne de grande distribution" },
  { slug: "plateforme_livraison", label: "Une plateforme de livraison" },
  { slug: "marque", label: "Une marque" },
  { slug: "evenementiel", label: "Un acteur de l'événementiel" },
  { slug: "autre", label: "Autre" },
] as const

interface IContactForm {
  location: "contact" | "pro"
}

const ContactForm: React.FunctionComponent<IContactForm> = props => {
  const [notificationSubject, setNotificationSubject] = React.useState<
    ContactType
  >("autre")
  const onContactTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as ContactType;
    setNotificationSubject(value)
  }
  const FORM_NAME = "Professionnels"
  return (
    <form
      name={FORM_NAME}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/merci"
      className={props.location === "pro" ? "form--background" : 'form--splash'}
    >
      <input type="hidden" name="form-name" value={FORM_NAME} />
      <input
        type="hidden"
        id="subject"
        name="subject"
        value={notificationSubject}
      />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <select
        required
        id="contact-type"
        name="contact-type"
        title="contact type"
        onChange={onContactTypeChange}
        defaultValue=""
      >
        <option disabled value="">
          Vous êtes...*
        </option>
        {CONTACT_TYPE.map(contactType => (
          <option key={contactType.slug} value={contactType.slug}>
            {contactType.label}
          </option>
        ))}
      </select>
      <input
        name="nom"
        id="nom"
        placeholder="Nom de votre établissement*"
        required
      />
      <input
        type="email"
        name="email"
        id="email"
        placeholder="E-mail*"
        required
        pattern="^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
      />
      <input
        name="telephone"
        id="telephone"
        placeholder="Numéro de téléphone"
      />
      <textarea name="message" id="message" rows={5} placeholder="Message" />
      <button className="link button button--color-pink" type="submit">
        Envoyer
      </button>
    </form>
  )
}

export default ContactForm
