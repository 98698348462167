import React from "react"

import Layout from "../components/Layout"
import ContactUs from "../components/pages/contact/ContactUs"
import SEO from "../components/Seo"

import "./contact.scss"

const Contact = () => (
  <Layout activePage="contact">
    <SEO
      title="Contact"
      description="Formulaire de contact pour les entreprises, les distributeurs et les particuliers."
      keywords={[
        "contact",
        "entreprise",
        "distributeur",
        "particulier",
        "consigne",
        "zéro",
        "déchet",
      ]}
    />
    <ContactUs />
  </Layout>
)

export default Contact
